import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {
  accessToken: string = "";
  refreshToken: string = "";

  constructor(private router: Router) {
    if (sessionStorage.getItem(`msal.token.keys.${environment.msalConfig.auth.clientId}`)) {
      const sessionKey = JSON.parse(sessionStorage.getItem(`msal.token.keys.${environment.msalConfig.auth.clientId}`) || "{}")
      this.accessToken = JSON.parse(sessionStorage.getItem(sessionKey.accessToken[0]) || "{}").secret || "";
      this.refreshToken = JSON.parse(sessionStorage.getItem(sessionKey.refreshToken) || "{}").secret || ""
    }
  }

  canActivate(route: ActivatedRouteSnapshot): UrlTree | boolean {
    const redirectUrl = route.queryParamMap.get('redirect_url');
    sessionStorage.setItem("test_redirect_url", redirectUrl || "")
    
    // if (this.accessToken == "") {
    //   return false;
    // }

    // if (redirectUrl) {
    //   const redurectUrlWithAccessToken = `${redirectUrl}/?access_token=${this.accessToken}&refresh_token=${this.refreshToken}`
    //   window.location.href = redurectUrlWithAccessToken;
    //   return true;
    // }
    return true;
  }
}